import { Navigate } from 'react-router-dom';
import { limpaStorage } from '../../helpers/funcoes';

const Logout = () => {
    /*const dispatch = useAppDispatch();
    dispatch(LOGOUT());*/
    limpaStorage();
    window.location.replace('/login');
    return <Navigate to="/" replace />;
};

export default Logout;
