import axios from 'axios';
import { msgAtencao } from './funcoes';
const API_URL = process.env.REACT_APP_URI;
const axiosApi = axios.create({
    baseURL: API_URL,
});

axiosApi.interceptors.request.use(function (config) {
    let storage = sessionStorage.getItem('paciente') || '';
    if (storage !== '') {
        const paciente = JSON.parse(storage);
        if (paciente && paciente.token !== undefined) config.headers.Authorization = 'Bearer ' + paciente.token;
    }
    config.headers['x-cardassi-x'] = '3';
    return config;
});

axiosApi.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        if (401 === error.response.status) {
            msgAtencao('Login expirado.');
            setTimeout(() => {
                sessionStorage.clear();
                window.location.replace('/login');
            }, 2500);
        } else {
            return Promise.reject(error);
        }
    }
);

export default axiosApi;
