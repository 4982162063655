import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { Global, css } from '@emotion/react';
import { ToastContainer } from 'react-toastify';
import Rotas from './rotas';
const GlobalStyles = css`
    /*
    This will hide the focus indicator if the element receives focus    via the mouse,
    but it will still show up on keyboard focus.
    */
    .js-focus-visible :focus:not([data-focus-visible-added]) {
        outline: none;
        box-shadow: none;
    }
`;

const breakpoints = {
    sm: '320px',
    md: '768px',
    lg: '960px',
    xl: '1200px',
    xl2: '1400px',
    xl3: '1600px',
    xl4: '1800px',
};

const theme = extendTheme({ breakpoints });

const App = (): JSX.Element => {
    return (
        <ChakraProvider theme={theme}>
            <Global styles={GlobalStyles} />
            <ToastContainer />
            <Rotas />
        </ChakraProvider>
    );
};

export default App;
