import { useState, useRef } from 'react';
import { Flex, Box, Grid, GridItem, FormControl, FormLabel, Input, Text, Select } from '@chakra-ui/react';
import Header from '../../components/Header';
import Button from '../../components/diversos/button';
import Loading from '../../components/Loading';
import { getCpf, msgAtencao, msgErro } from '../../helpers/funcoes';
import axiosApi from '../../helpers/axios';

const Cadastro = () => {
    const _cpf = getCpf();
    const [item, setItem] = useState({
        cpf: _cpf,
        nome: '',
        telefone: '',
        email: '',
        inscricao: '',
        senha: '',
        sexo: 'F',
        nascimento: '',
    });

    const [erro, setErro] = useState('');
    const [loading, setLoading] = useState(false);

    const cpf = useRef<HTMLInputElement>(null);
    const nome = useRef<HTMLInputElement>(null);
    const telefone = useRef<HTMLInputElement>(null);
    const sexo = useRef<HTMLSelectElement>(null);
    const senha = useRef<HTMLInputElement>(null);

    function handleChange(evt: any) {
        let itemAux = item;
        if (evt.target.name === 'cpf') {
            const valor = evt.target.validity.valid ? evt.target.value : item.cpf;
            itemAux = { ...itemAux, [evt.target.name]: valor };
        } else {
            itemAux = { ...itemAux, [evt.target.name]: evt.target.value };
        }
        setItem(itemAux);
    }

    const handleCadastro = (e: any) => {
        e.preventDefault();
        if (item.cpf && item.cpf.trim() === '') {
            msgAtencao('Favor informar o CPF.');
            if (cpf.current) cpf.current.focus();
            return;
        }

        if (item.nome.trim() === '') {
            msgAtencao('Favor informar o nome.');
            if (nome.current) nome.current.focus();
            return;
        }

        if (item.telefone.trim() === '') {
            msgAtencao('Favor informar o telefone.');
            if (telefone.current) telefone.current.focus();
            return;
        }

        if (item.sexo.trim() === '') {
            msgAtencao('Favor informar o sexo.');
            if (sexo.current) sexo.current.focus();
            return;
        }

        if (item.senha.trim() === '') {
            msgAtencao('Favor informar a senha.');
            if (senha.current) senha.current.focus();
            return;
        }

        setLoading(true);

        axiosApi
            .post('/clinica/cadastro', item)
            .then(() => {
                setLoading(false);
                window.location.replace('/login');
            })
            .catch((error) => {
                setLoading(false);
                let mensagem = '';
                if (error.response.data) mensagem = error.response.data.error;
                else mensagem = 'Não foi possível realizar o cadastro.';
                setErro(mensagem);
                msgErro(mensagem);
            });
    };

    return (
        <Flex
            display="flex"
            flexDirection="column"
            minH="100vh"
            maxW="1200px"
            margin="auto"
            boxShadow="dark-lg"
            px={{
                lg: 0,
                xl: 0,
                base: 2,
            }}
        >
            <Header />
            <Flex
                px={{
                    lg: 3,
                    xl: 3,
                    base: 1,
                }}
                flex="1"
                w="100%"
                mt={2}
                pb={3}
            >
                <Box as="form" id="frmDados" w="100%" maxW="500px">
                    <Grid templateColumns="repeat(2, 1fr)" w="100%" gap="10px">
                        <GridItem>
                            <FormControl id="cpf">
                                <FormLabel>CPF</FormLabel>
                                <Input
                                    placeholder="12312312312"
                                    name="cpf"
                                    value={item.cpf}
                                    onChange={(e) => handleChange(e)}
                                    ref={cpf}
                                    maxLength={11}
                                    type="text"
                                    pattern="[0-9]*"
                                    autoComplete="off"
                                />
                            </FormControl>
                        </GridItem>
                        <GridItem colSpan={2}>
                            <FormControl id="nome">
                                <FormLabel>Nome</FormLabel>
                                <Input
                                    placeholder="NOME DO PACIENTE"
                                    name="nome"
                                    value={item.nome}
                                    onChange={(e) => handleChange(e)}
                                    ref={nome}
                                    maxLength={60}
                                    type="text"
                                />
                            </FormControl>
                        </GridItem>
                        <GridItem>
                            <FormControl id="telefone">
                                <FormLabel>Telefone (WhatsApp)</FormLabel>
                                <Input
                                    placeholder="(XX) XXXXX-XXXXX"
                                    name="telefone"
                                    value={item.telefone}
                                    onChange={(e) => handleChange(e)}
                                    ref={telefone}
                                    maxLength={20}
                                    type="text"
                                />
                            </FormControl>
                        </GridItem>
                        <GridItem>
                            <FormControl id="inscricao">
                                <FormLabel>Inscrição</FormLabel>
                                <Input
                                    placeholder="102030"
                                    name="inscricao"
                                    value={item.inscricao}
                                    onChange={(e) => handleChange(e)}
                                    maxLength={8}
                                    type="text"
                                />
                            </FormControl>
                        </GridItem>
                        <GridItem>
                            <FormControl id="sexo">
                                <FormLabel>Sexo</FormLabel>
                                <Select
                                    placeholder="Selecione"
                                    value={item.sexo}
                                    ref={sexo}
                                    onChange={(e) => {
                                        let itemAux = item;
                                        itemAux = {
                                            ...itemAux,
                                            sexo: e.target.value,
                                        };
                                        setItem(itemAux);
                                    }}
                                >
                                    <option value="F">Feminino</option>
                                    <option value="M">Masculino</option>
                                </Select>
                            </FormControl>
                        </GridItem>
                        <GridItem>
                            <FormControl id="nascimento">
                                <FormLabel>Nascimento</FormLabel>
                                <Input
                                    placeholder="01/01/2000"
                                    name="nascimento"
                                    value={item.nascimento}
                                    onChange={(e) => handleChange(e)}
                                    type="date"
                                />
                            </FormControl>
                        </GridItem>
                        <GridItem colSpan={2}>
                            <FormControl id="email">
                                <FormLabel>E-mail</FormLabel>
                                <Input
                                    placeholder="email@email.com.br"
                                    name="email"
                                    value={item.email}
                                    onChange={(e) => handleChange(e)}
                                    maxLength={100}
                                    type="text"
                                />
                            </FormControl>
                        </GridItem>
                        <GridItem>
                            <FormControl id="senha">
                                <FormLabel>Senha</FormLabel>
                                <Input
                                    name="senha"
                                    value={item.senha}
                                    onChange={(e) => handleChange(e)}
                                    maxLength={20}
                                    type="password"
                                    ref={senha}
                                />
                            </FormControl>
                        </GridItem>
                    </Grid>
                    {erro ? (
                        <Box color="red" fontWeight="bold">
                            <Text>{erro}</Text>
                        </Box>
                    ) : null}
                    <Button mt={4} mr={4} disabled={loading} onClick={handleCadastro} w="150px">
                        {loading && <Loading />}
                        <span>Cadastrar</span>
                    </Button>
                    <Button mt={4} onClick={() => window.location.replace('/')} w="150px" disabled={loading}>
                        <span>Voltar</span>
                    </Button>
                </Box>
            </Flex>
        </Flex>
    );
};

export default Cadastro;
