import { Box, FormLabel, Select, Grid, GridItem, Text, Flex } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import Calendar from 'react-calendar';
import moment from 'moment';

import Base from '../../components/base';
import { dataBancoDados, dataExibicao, getCpf, msgSucesso, tratarErro } from '../../helpers/funcoes';
import Button from '../../components/diversos/button';
import Loading from '../../components/Loading';
import Confirmar from '../../components/diversos/confirmar';
import { useGetLoginCpfQuery } from '../../redux/api/authApi';
import {
    useLazyGetConveniadosQuery,
    useLazyGetEspecialidadesQuery,
    useLazyGetHorarioMesQuery,
    useLazyGetHorarioQuery,
    usePostAgendarMutation,
} from '../../redux/api/clinicaApi';
import cidades from '../../assets/cidades.json';

const dataInicial = new Date(new Date().valueOf() + 1000 * 3600 * 24);

const Home = () => {
    const _cpf = getCpf();
    const { data: paciente } = useGetLoginCpfQuery(_cpf, { skip: _cpf === '' });

    const [getEspecialidades, { data: especialidades }] = useLazyGetEspecialidadesQuery();
    const [getConveniados, { data: conveniados }] = useLazyGetConveniadosQuery();
    const [getHorarioMes, { data: horarioMes }] = useLazyGetHorarioMesQuery();
    const [getHorario, { data: horario }] = useLazyGetHorarioQuery();
    const [postAgendar, { data: dadosInsert, isSuccess: isSuccessInsert, error: insertError }] =
        usePostAgendarMutation();

    const [cidade, setCidade] = useState('aracatuba');
    const [tipo, setTipo] = useState('m');
    const [especialidade, setEspecialidade] = useState('');
    const [conveniado, setConveniado] = useState('');
    const [dia, setDia] = useState(dataInicial);
    const [dataLivre, setDataLivre] = useState([]);
    const [dataView, setdataView] = useState(dataInicial);
    const [dlgOpen, setDlgOpen] = useState(false);
    const [hora, setHora] = useState('');
    const [loading, setLoading] = useState(false);
    const [loadingEspecialidades, setLoadingEspecialidades] = useState(false);
    const [loadingConveniados, setLoadingConveniados] = useState(false);

    // Faz o mapeamento das especialidades por cidade na inicialização da tela
    useEffect(() => {
        try {
            cidades.map((cidade) => {
                getEspecialidades({ tipo: 'm', cidade: cidade.value }, true);
                getEspecialidades({ tipo: 'o', cidade: cidade.value }, true);
            });
        } catch (erro) {
            console.log('ERRO AO PEGAR AS INFORMAÇÕES DE ESPECIALIDADES', erro);
        }
    }, []);

    // Quando faz a troca de cidade ou tipo recarrega as especialidades
    useEffect(() => {
        setConveniado('');
        setEspecialidade('');
        buscaEspecialidades();
    }, [cidade, tipo]);

    const buscaEspecialidades = async () => {
        setLoadingEspecialidades(true);
        await getEspecialidades({ tipo, cidade }, true).unwrap();
        setLoadingEspecialidades(false);
    };

    /*
        Quando seleciona uma especialidade na lista, recarrega os
        conveniados da cidade selecionada que atendem essa especialidade
    */
    useEffect(() => {
        setDataLivre([]);
        buscaConveniados();
    }, [especialidade]);

    const buscaConveniados = async () => {
        if (especialidade === '') return;
        setLoadingConveniados(true);
        await getConveniados({ especialidade, cidade }, true).unwrap();
        setLoadingConveniados(false);
    };

    // Busca os dias que o conveniado tem horário disponível
    async function buscarHorariosMes() {
        if (!conveniado) return;
        setLoading(true);
        setDataLivre([]);
        await getHorarioMes({ conveniado, data: dataBancoDados(dataView), cidade }, true).unwrap();
        setLoading(false);
    }

    useEffect(() => {
        if (horarioMes === undefined) return;
        setDataLivre(horarioMes);
    }, [horarioMes]);

    // Busca os horários disponiveis no dia selecionado
    useEffect(() => {
        if (!conveniado) return;
        buscarHorarios();
    }, [dia]);

    async function buscarHorarios(usarCache: boolean = true) {
        if (!conveniado) return;
        setLoading(true);
        await getHorario({ conveniado, data: dataBancoDados(dia), cidade }, usarCache).unwrap();
        setLoading(false);
    }

    // Quando troca o mês do calendario
    function viewChange(activeStartDate: any) {
        setdataView(activeStartDate);
        if (new Date(activeStartDate) >= dataInicial) setDia(activeStartDate);
        else setDia(dataInicial);
    }

    // Quando troca o mês do calendario ou o conveniado selecionado
    useEffect(() => {
        if (!conveniado) {
            setDataLivre([]);
            return;
        }
        setDataLivre([]);
        const fetchData = async () => {
            await buscarHorariosMes();
            await buscarHorarios();
        };
        fetchData();
    }, [dataView, conveniado]);

    function handleAgendar() {
        let itemEnviar = {
            cliente: paciente && paciente.id,
            conveniado,
            tipo,
            cidade,
            especialidade,
            data: dataBancoDados(dia),
            hora,
        };
        postAgendar({ item: itemEnviar });
    }
    // Caso ocorra algum erro
    useEffect(() => {
        if (insertError === undefined) return;
        tratarErro(insertError);
    }, [insertError]);

    useEffect(() => {
        if (isSuccessInsert === true) {
            msgSucesso('Consulta agendada com sucesso.', buscarHorarios(false));
        }
    }, [isSuccessInsert]);

    return (
        <Base>
            <Box mb={2}>
                <Grid templateColumns="repeat(3, 1fr)" gap={4}>
                    <GridItem
                        colSpan={{
                            lg: 1,
                            base: 3,
                        }}
                    >
                        <FormLabel>Cidade</FormLabel>
                        <Select
                            placeholder="Selecione a cidade"
                            value={cidade}
                            onChange={(e) => {
                                setCidade(e.target.value);
                            }}
                        >
                            {cidades.map((cidade) => (
                                <option key={cidade.value} value={cidade.value}>
                                    {cidade.exibicao}
                                </option>
                            ))}
                        </Select>
                    </GridItem>
                    <GridItem
                        colSpan={{
                            lg: 1,
                            base: 3,
                        }}
                    >
                        <FormLabel>Tipo de consulta</FormLabel>
                        <Select
                            value={tipo}
                            onChange={(e) => {
                                setTipo(e.target.value);
                            }}
                        >
                            <option value="m">Medicina</option>
                            <option value="o">Odontologia</option>
                        </Select>
                    </GridItem>
                    <GridItem
                        colSpan={{
                            lg: 1,
                            base: 3,
                        }}
                    >
                        <FormLabel>Especialidade</FormLabel>
                        {loadingEspecialidades && (
                            <Box marginY="0" display="flex" flexDirection="column-reverse">
                                <Loading />
                            </Box>
                        )}
                        {!loadingEspecialidades && (
                            <Select
                                placeholder="Selecione a especialidade"
                                value={especialidade}
                                onChange={(e) => {
                                    setConveniado('');
                                    setEspecialidade(e.target.value);
                                }}
                            >
                                {especialidades &&
                                    especialidades.length > 0 &&
                                    especialidades.map((especialidade: any) => {
                                        return (
                                            <option key={especialidade.id} value={especialidade.id}>
                                                {especialidade.descricao}
                                            </option>
                                        );
                                    })}
                            </Select>
                        )}
                    </GridItem>

                    <GridItem
                        colSpan={{
                            lg: 2,
                            base: 3,
                        }}
                    >
                        {especialidade && <FormLabel>Conveniados</FormLabel>}
                        {especialidade !== '' && loadingConveniados && <Loading />}
                        {especialidade !== '' && !loadingConveniados && (
                            <Select
                                placeholder="Selecione os conveniados"
                                value={conveniado}
                                onChange={(e) => {
                                    setConveniado(e.target.value);
                                }}
                            >
                                {conveniados &&
                                    conveniados.length > 0 &&
                                    conveniados.map((conveniado: any) => {
                                        return (
                                            <option key={conveniado.id} value={conveniado.id}>
                                                {conveniado.nome}
                                            </option>
                                        );
                                    })}
                            </Select>
                        )}
                    </GridItem>
                    <GridItem
                        colSpan={{
                            lg: 1,
                            base: 0,
                        }}
                    ></GridItem>
                    {conveniado !== '' && (
                        <GridItem
                            colSpan={{
                                lg: 1,
                                base: 3,
                            }}
                            m={'0 auto'}
                        >
                            <Calendar
                                showNeighboringMonth={false}
                                onChange={(value) => setDia(value as Date)}
                                onActiveStartDateChange={({ activeStartDate }) => viewChange(activeStartDate)}
                                value={dia}
                                minDate={dataInicial}
                                maxDate={new Date(new Date().valueOf() + 180000 * 3600 * 24)}
                                tileClassName={({ date, view }) => {
                                    let classe = '';
                                    if (dataLivre.find((x: any) => x.horario === moment(date).format('YYYY-MM-DD'))) {
                                        classe = 'marcaData';
                                    }
                                    if (moment(date).isoWeekday() === 6) {
                                        if (dataExibicao(date) === dataExibicao(dia))
                                            classe = `${classe} sabadoSelecionado`;
                                        else classe = `${classe} sabado`;
                                    }
                                    return classe;
                                }}
                            />
                            <Flex flexDir={'row'} lineHeight={'30px'} mt={2}>
                                <Box minW={'30px'} minH={'30px'} className={'marcaData'} mr={2} />
                                <Text>Dias com horário disponível</Text>
                            </Flex>
                        </GridItem>
                    )}
                    <GridItem colSpan={{ lg: 1, base: 3 }} mb={10}>
                        {!loading && conveniado !== '' && (horario === undefined || horario.length === 0) && (
                            <Text>Nenhum horário para o dia escolhido.</Text>
                        )}
                        {!loading && conveniado !== '' && horario !== undefined && horario.length !== 0 && (
                            <Grid
                                templateColumns="repeat(1, 1fr)"
                                gap={1}
                                display={'flex'}
                                flexWrap={'wrap'}
                                justifyContent={'center'}
                            >
                                {horario.map((horario: any) => {
                                    return (
                                        <Button
                                            onClick={() => {
                                                setDlgOpen(true);
                                                setHora(horario.horario);
                                            }}
                                            key={horario.horario}
                                            w={20}
                                        >
                                            {horario.horario}
                                        </Button>
                                    );
                                })}
                            </Grid>
                        )}
                    </GridItem>
                </Grid>
                {loading && <Loading />}
            </Box>
            <Confirmar
                titulo="Confirmar"
                texto={`Confirma o agendamento às ${hora}?`}
                isOpen={dlgOpen}
                toggleOpen={setDlgOpen}
                confirmar={handleAgendar}
            />
        </Base>
    );
};

export default Home;
