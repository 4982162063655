import { Box, Heading, Text, Grid, GridItem, IconButton, Flex, Tooltip } from '@chakra-ui/react';
import Base from '../../components/base';
import { useEffect, useState } from 'react';
import Loading from '../../components/Loading';
import { dataHoraExibicao, getCpf, msgErro, tratarErro } from '../../helpers/funcoes';
import { useGetLoginCpfQuery } from '../../redux/api/authApi';
import { useLazyGetConsultasQuery, usePutConsultasMutation } from '../../redux/api/clinicaApi';
import { DeleteIcon } from '@chakra-ui/icons';
import ConfirmarExclusao from '../../components/diversos/confirmarExclusao';

const Consultas = () => {
    const _cpf = getCpf();
    const { data: paciente } = useGetLoginCpfQuery(_cpf, { skip: _cpf === '' });

    const [dlgOpen, setDlgOpen] = useState(false);
    const [horarios, setHorarios] = useState([]);
    const [loading, setLoading] = useState(false);
    const [getConsultas] = useLazyGetConsultasQuery();
    const [idCancelar, setIdCancelar] = useState('');

    const [putConsultas, { data: dadosUpdate, isSuccess: isSuccessUpdate, error: UpdateError }] =
        usePutConsultasMutation();

    useEffect(() => {
        buscarConsultas();
    }, []);

    const buscarConsultas = () => {
        setLoading(true);
        getConsultas({ id: paciente.id })
            .unwrap()
            .then((dados: any) => {
                setLoading(false);
                setHorarios(dados);
            })
            .catch((error: any) => {
                setLoading(false);
                let mensagem = '';
                if (error.response.data) mensagem = error.response.data.error;
                else mensagem = 'Um erro inesperado ocorreu ao localizar as consultas.';
                msgErro(mensagem);
            });
    };

    const handleCancelarConsulta = () => {
        putConsultas({ id: idCancelar });
    };

    useEffect(() => {
        if (UpdateError === undefined) return;
        tratarErro(UpdateError);
    }, [UpdateError]);

    useEffect(() => {
        if (isSuccessUpdate) {
            buscarConsultas();
        }
    }, [isSuccessUpdate]);

    return (
        <Base>
            <Box mb={2}>
                {loading ? (
                    <Loading />
                ) : horarios.length === 0 ? (
                    <Text>Nenhuma consulta cadastrada.</Text>
                ) : (
                    <Grid templateColumns="repeat(3, 1fr)" w="100%" gap={6}>
                        {horarios.map((horario: any) => {
                            return (
                                <GridItem
                                    key={horario.data}
                                    colSpan={{
                                        lg: 1,
                                        xl: 1,
                                        base: 3,
                                    }}
                                >
                                    <Box
                                        boxShadow="dark-lg"
                                        p="6"
                                        rounded="md"
                                        height={150}
                                        color={horario.status === 'C' ? 'red' : 'black'}
                                    >
                                        <Flex>
                                            <Box>
                                                <Heading size="md">{dataHoraExibicao(horario.data)}</Heading>
                                                <Text fontWeight="bold">Conveniado</Text>
                                                <Text>{horario.conveniado}</Text>
                                            </Box>
                                            {horario.status !== 'C' && (
                                                <Tooltip label="Cancelar Consulta">
                                                    <IconButton
                                                        aria-label="Cancelar Consulta"
                                                        icon={<DeleteIcon />}
                                                        colorScheme={'red'}
                                                        size={'sm'}
                                                        onClick={() => {
                                                            setIdCancelar(horario.id);
                                                            setDlgOpen(true);
                                                        }}
                                                    />
                                                </Tooltip>
                                            )}
                                        </Flex>
                                    </Box>
                                </GridItem>
                            );
                        })}
                    </Grid>
                )}
            </Box>
            <ConfirmarExclusao
                titulo="Confirmar"
                texto={`Confirma o cancelamento da consulta?`}
                isOpen={dlgOpen}
                toggleOpen={setDlgOpen}
                apagar={handleCancelarConsulta}
            />
        </Base>
    );
};

export default Consultas;
