import { Routes, Route, Navigate } from 'react-router-dom';
import { chaveExiste, getTipo } from './helpers/funcoes';
import Home from './pages/Home';
import Logout from './pages/LogOut';
import Cadastro from './pages/Cadastro';
import Alterar from './pages/Alterar';
import Resetar from './pages/Resetar';
import Pagamento from './pages/Pagamento';
import LoginSenha from './pages/LoginSenha';
import Consultas from './pages/Consultas';
import Unidades from './pages/Unidades';
import Login from './pages/Login';

function RequireAuth({ children }: { children: JSX.Element }) {
    const temChave = chaveExiste();
    const tipo = getTipo();
    console.log('chave, tipo', temChave, tipo);
    if (!temChave && tipo === '') {
        return <Navigate to="/login" replace />;
    }

    return children;
}

const Rotas = () => {
    if (!chaveExiste()) {
        return (
            <Routes>
                <Route path="/login" element={<Login />} />
                <Route path="/cadastro" element={<Cadastro />} />
                <Route path="/resetar" element={<Resetar />} />
                <Route path="/pagamento" element={<Pagamento />} />
                <Route path="/alterar" element={<Alterar />} />
                <Route path="/senha" element={<LoginSenha />} />
                <Route path="*" element={<Login />} />
            </Routes>
        );
    }

    return (
        <Routes>
            <Route path="/logout" element={<Logout />} />
            <Route
                path="/"
                element={
                    <RequireAuth>
                        <Home />
                    </RequireAuth>
                }
            />
            <Route
                path="/consultas"
                element={
                    <RequireAuth>
                        <Consultas />
                    </RequireAuth>
                }
            />
            <Route
                path="/unidades"
                element={
                    <RequireAuth>
                        <Unidades />
                    </RequireAuth>
                }
            />
            <Route path="*" element={<Home />} />
        </Routes>
    );
};

export default Rotas;
