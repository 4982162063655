import { Grid } from '@chakra-ui/react';
import Base from '../../components/base';
import CardUnidade from '../../components/diversos/cardUnidade';

const Unidades = () => {
    return (
        <Base>
            <Grid templateColumns="repeat(3, 1fr)" w="100%" gap={6}>
                <CardUnidade
                    cabecalho="Andradina"
                    endereco="Av: Guanabara, 1833"
                    cep="16901-100"
                    bairro="Stella Maris"
                    cidade="Andradina/SP"
                    telefone="(18) 3723-6030"
                    responsavel="CD/RT Dra. Juliana Mariana Sa de Carvalho Nakasse"
                    registro="CROCL 020874 - CROSP 95254 - Clínico Geral"
                    link="https://goo.gl/maps/zgKb4RLAKboaUk1J8"
                />
                <CardUnidade
                    cabecalho="Araçatuba"
                    endereco="Rua Tiradentes, 1190"
                    cep="16015-020"
                    bairro="Vila Mendonça"
                    cidade="Araçatuba/SP"
                    telefone="(18) 3301-9998"
                    responsavel="CD/RT Dr. Alexandre Rocha Costa"
                    registro="CROCL 19158 - CROSP7511 - Clínico Geral"
                    link="https://goo.gl/maps/dar2imQHbGrge6tdA"
                />
                <CardUnidade
                    cabecalho="Birigui"
                    endereco="Rua Saudades, 270"
                    cep="16200-007"
                    bairro="Centro"
                    cidade="Birigui/SP"
                    telefone="(18) 3644-9886"
                    responsavel="CD/RT Dr. Marco Aurelio Borella Rodrigues"
                    registro="CROCL 020798 - CROSP 90712 - Clínico Geral"
                    link="https://goo.gl/maps/LYYP6LNHkS8cgacX7"
                />
                <CardUnidade
                    cabecalho="Três Lagoas"
                    endereco="Av. Rosário Congro, 1233"
                    cep="79603-110"
                    bairro="Colinos"
                    cidade="Três Lagoas/MS"
                    telefone="(67) 3531-1765"
                    responsavel="CD/RT Dr. Anderson Gonçalves Frias"
                    registro="CRO-MS 5230 - Clínico Geral"
                    link="https://goo.gl/maps/a1pgYFKGvSFLMS7u9"
                />
                <CardUnidade
                    cabecalho="Valparaíso"
                    endereco="Rua Humberto A. Castelo Branco, 737"
                    cep="16880-000"
                    bairro="Centro"
                    cidade="Valparaíso/SP"
                    telefone="(18) 3401-1896"
                    responsavel="CD/RT Dra. Patrícia Rodrigues Kasaki Tobita"
                    registro="CROCL 021099 - CROSP 55196 - Clínico Geral"
                    link="https://goo.gl/maps/vQqMNMcnhXrQeuu36"
                />
            </Grid>
        </Base>
    );
};

export default Unidades;
