import ReactDOM from 'react-dom/client';
import { ApiProvider } from '@reduxjs/toolkit/dist/query/react';
import { BrowserRouter } from 'react-router-dom';

import { baseApi } from './redux/api/baseApi';
import './index.css';
import 'focus-visible/dist/focus-visible';
import 'react-toastify/dist/ReactToastify.css';
import 'react-calendar/dist/Calendar.css';
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <ApiProvider api={baseApi}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </ApiProvider>
);
