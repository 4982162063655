import { Box, GridItem, Heading, Text } from '@chakra-ui/react';

interface propriedades {
    cabecalho: string;
    endereco: string;
    cep: string;
    bairro: string;
    cidade: string;
    telefone: string;
    responsavel: string;
    registro: string;
    link?: string;
}

const CardUnidade = (props: propriedades) => {
    return (
        <GridItem
            colSpan={{
                lg: 1,
                xl: 1,
                base: 3,
            }}
        >
            <Box boxShadow="dark-lg" p="6" rounded="md" height={250}>
                <Heading size="md">{props.cabecalho}</Heading>
                {props.link !== '' && (
                    <a href={props.link} target={'_blank'} rel="noreferrer">
                        <Text>{props.endereco}</Text>
                    </a>
                )}
                {props.link === '' && <Text>{props.endereco}</Text>}

                <Text>
                    CEP: {props.cep} - {props.bairro}
                </Text>
                <Text>{props.cidade}</Text>
                <a href={`tel:${props.telefone}`}>
                    <Text>Tel.: {props.telefone}</Text>
                </a>
                <Text>{props.responsavel}</Text>
                <Text>{props.registro}</Text>
            </Box>
        </GridItem>
    );
};

export default CardUnidade;
