import {
    Box,
    Image,
    Heading,
    useDisclosure,
    Drawer,
    DrawerOverlay,
    DrawerContent,
    DrawerBody,
    Spacer,
} from '@chakra-ui/react';
import NavLink from '../NavLink';
import Button from '../diversos/button';
import { HamburgerIcon } from '@chakra-ui/icons';
import { useGetLoginCpfQuery } from '../../redux/api/authApi';
import { chaveExiste, getCpf } from '../../helpers/funcoes';
const logo = require('../../assets/logo_santarita.png');

const Header = () => {
    const cpf = getCpf();
    const { data: paciente } = useGetLoginCpfQuery(cpf, { skip: cpf === '' });
    const { isOpen, onOpen, onClose } = useDisclosure();

    const menuGrande = () => {
        if (paciente === undefined || !chaveExiste()) return <></>;
        return (
            <Box
                display={{ lg: 'flex', xl: 'flex', base: 'none' }}
                backgroundColor="gray.50"
                mx={{
                    lg: -3,
                    xl: -3,
                    base: -1,
                }}
                px={{
                    lg: 3,
                    xl: 3,
                    base: 1,
                }}
            >
                <NavLink to="/">Nova Consulta</NavLink>
                <Spacer />
                <NavLink to="/consultas">Consultas Agendadas</NavLink>
                <Spacer />
                <NavLink to="/unidades">Unidades</NavLink>
                <Spacer />
                <NavLink to="/logout">Sair</NavLink>
            </Box>
        );
    };

    const menuCompacto = () => {
        if (paciente === undefined) return <></>;
        return (
            <Drawer
                placement="left"
                onClose={onClose}
                isOpen={isOpen}
                //display={{ lg: 'none', xl: 'none' }}
            >
                <DrawerOverlay>
                    <DrawerContent>
                        <DrawerBody>
                            <NavLink to="/">Nova Consulta</NavLink>
                            <NavLink to="/consultas">Consultas Agendadas</NavLink>
                            <NavLink to="/unidades">Unidades</NavLink>
                            <NavLink to="/logout">Sair</NavLink>
                        </DrawerBody>
                    </DrawerContent>
                </DrawerOverlay>
            </Drawer>
        );
    };

    return (
        <Box
            boxShadow="lg"
            borderRadius={4}
            px={{
                lg: 3,
                xl: 3,
                base: 1,
            }}
            // @ts-ignore
            position={{ lg: 'sticky', xl: 'sticky', base: null }}
            top="0"
            zIndex={999}
            backgroundColor="white"
            mb={3}
        >
            <>
                <Box
                    p="3"
                    display="flex"
                    justifyContent={{
                        lg: 'left',
                        xl: 'left',
                        base: 'center',
                    }}
                >
                    <Image src={logo} />
                </Box>
                {menuGrande()}
                {menuCompacto()}
                {paciente !== undefined ? (
                    <>
                        <Box mb={2} mt={2}>
                            <Box display="flex" flexDir={{ lg: 'row', base: 'column' }} w="100%" mb={2}>
                                <>
                                    <Box w="150px">
                                        <Heading size="sm">ID: {paciente.id}</Heading>
                                    </Box>
                                    <Box flex="1">
                                        <Heading size="sm">Nome: {paciente.nome}</Heading>
                                    </Box>
                                </>
                            </Box>
                        </Box>
                        <Button onClick={onOpen} display={{ lg: 'none', xl: 'none' }} mb={2}>
                            <HamburgerIcon w={6} h={6} />
                        </Button>
                    </>
                ) : (
                    <></>
                )}
            </>
        </Box>
    );
};

export default Header;
