import { IPaciente, respostaLoginCpf } from '../../react-app-env';
import { baseApi } from './baseApi';

const apiWithTag = baseApi.enhanceEndpoints({ addTagTypes: ['Login'] });

export const authApi = apiWithTag.injectEndpoints({
    endpoints: (builder) => ({
        getLoginCpf: builder.query<any, string>({
            query(cpf) {
                let url = `clinica/login/${cpf}`;
                return {
                    url,
                };
            },
            providesTags: (result) => ['Login'],
            transformResponse: (response: respostaLoginCpf) => {
                return {
                    id: response.id,
                    nome: response.nome,
                    tipo: response.resposta,
                };
            },
        }),
        postLoginCpf: builder.mutation<any, { cpf: string; item: {} }>({
            query({ cpf, item }) {
                let url = `clinica/login/${cpf}`;
                return {
                    url: url,
                    method: 'POST',
                    //credentials: 'include',
                    body: item,
                };
            },
            async onQueryStarted({ cpf, item }, { dispatch, queryFulfilled }) {
                try {
                    const { data: updatedPost } = await queryFulfilled;
                    const patchResult = dispatch(
                        authApi.util.updateQueryData('getLoginCpf', cpf, (draft) => {
                            sessionStorage.setItem('token', JSON.stringify(updatedPost.token));
                            Object.assign(draft, updatedPost);
                        })
                    );
                } catch {}
            },
        }),
        logoutUser: builder.mutation<void, void>({
            query() {
                return {
                    url: 'logout',
                    method: 'POST',
                    credentials: 'include',
                };
            },
            async onQueryStarted(_, { dispatch }) {
                dispatch(baseApi.util.resetApiState());
                console.log('PASSOU NO LOGOUT');
                sessionStorage.clear();
            },
        }),
    }),
    overrideExisting: true,
});

export const { useGetLoginCpfQuery, useLazyGetLoginCpfQuery, usePostLoginCpfMutation, useLogoutUserMutation } = authApi;
