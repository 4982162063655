import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay,
    useColorModeValue,
} from '@chakra-ui/react';
import { useRef } from 'react';
import Button from './button';

interface Props {
    titulo: string;
    texto: string;
    isOpen: boolean;
    toggleOpen: Function;
    confirmar: Function;
}

const Confirmar = ({ titulo, texto, isOpen, toggleOpen, confirmar }: Props) => {
    const onClose = () => toggleOpen(false);
    const cancelRef = useRef<HTMLButtonElement>(null);
    const onConfirmar = () => {
        confirmar();
        toggleOpen(false);
    };
    const bgBox = useColorModeValue('white', 'gray.800');

    return (
        <AlertDialog
            isOpen={isOpen}
            leastDestructiveRef={cancelRef}
            onClose={onClose}
            closeOnOverlayClick={false}
            onEsc={onClose}
            isCentered
        >
            <AlertDialogOverlay>
                <AlertDialogContent bg={bgBox}>
                    <AlertDialogHeader fontSize="lg" fontWeight="bold">
                        {titulo}
                    </AlertDialogHeader>

                    <AlertDialogBody>{texto}</AlertDialogBody>

                    <AlertDialogFooter>
                        <Button
                            colorScheme={'red'}
                            ref={cancelRef as React.LegacyRef<HTMLButtonElement> | undefined}
                            onClick={onClose}
                        >
                            Cancelar
                        </Button>
                        <Button onClick={onConfirmar} ml={3}>
                            Confirmar
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
    );
};

export default Confirmar;
