import { Box, Flex, FormControl, FormLabel, Input, Image, Text } from '@chakra-ui/react';
import { useState, useRef, useEffect } from 'react';
import Loading from '../../components/Loading';
import { chaveExiste, msgAtencao, tratarErro } from '../../helpers/funcoes';
import Button from '../../components/diversos/button';
import { useNavigate } from 'react-router';
import { useLazyGetLoginCpfQuery } from '../../redux/api/authApi';

const logo = require('../../assets/logo_santarita.png');

const Login = () => {
    const [getDados, { data: dados, isLoading, /*isSuccess, isError,*/ error: queryError }] = useLazyGetLoginCpfQuery();
    const [cpf, setCpf] = useState('');

    let navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const icpf = useRef<HTMLInputElement>(null);

    useEffect(() => {
        setLoading(false);
        if (queryError === undefined) return;
        tratarErro(queryError);
    }, [queryError]);

    useEffect(() => {
        if (cpf !== '') sessionStorage.setItem('cpf', JSON.stringify(cpf));
        if (dados !== undefined) {
            sessionStorage.setItem('tipo', JSON.stringify(dados.tipo));
            if (dados.tipo === '1') {
                return navigate('/cadastro', { replace: true });
            } else if (dados.tipo === '2') {
                return navigate('/alterar', { replace: true });
            } else if (dados.tipo === '3') {
                return navigate('/senha', { replace: true });
            }
        }
        sessionStorage.removeItem('cpf');
        sessionStorage.removeItem('tipo');
    }, [dados]);

    const onChangeCpf = (e: any) => {
        const valor = e.target.validity.valid ? e.target.value : cpf;
        setCpf(valor);
    };

    const handleLogin = async (e: any) => {
        e.preventDefault();
        if (cpf.trim() === '') {
            msgAtencao('Favor informar o CPF.');
            if (icpf.current) icpf.current.focus();
            return;
        }
        setLoading(true);
        await getDados(cpf);
    };

    if (dados !== undefined && chaveExiste()) {
        navigate('/');
    }

    return (
        <Flex minHeight="100vh" width="full" align="center" justifyContent="center">
            <Box
                px={4}
                width="full"
                maxWidth={{ lg: '500px', xl: '500px', base: '90%' }}
                borderRadius={4}
                textAlign="center"
                boxShadow="dark-lg"
            >
                <Box p={4}>
                    <Box display="flex" justifyContent="center" alignItems="center">
                        <Image src={logo} />
                    </Box>
                    <Box justifyContent="center" alignItems="center">
                        <Text fontSize="2xl" fontWeight="500">
                            LOGIN
                        </Text>
                    </Box>
                    <Box my={4} textAlign="left">
                        <form
                            onSubmit={(e) => {
                                e.preventDefault();
                                handleLogin(e);
                            }}
                        >
                            <FormControl>
                                <FormLabel>CPF</FormLabel>
                                <Input
                                    placeholder="Informe o CPF"
                                    maxLength={11}
                                    value={cpf}
                                    onChange={onChangeCpf}
                                    autoComplete="off"
                                    type="number"
                                    pattern="[0-9]*"
                                    ref={icpf}
                                    autoFocus
                                />
                            </FormControl>
                            <Button mt={4} mr={4} disabled={loading} onClick={handleLogin} w="150px">
                                {loading && <Loading />}
                                <span>Verificar</span>
                            </Button>
                        </form>
                    </Box>
                </Box>
            </Box>
        </Flex>
    );
};

export default Login;
