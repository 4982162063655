import { Box, Flex, FormControl, FormLabel, Input, Image, Text } from '@chakra-ui/react';
import { useState, useRef, useEffect } from 'react';
import Loading from '../../components/Loading';
import { tratarErro, msgAtencao, msgErro, getCpf } from '../../helpers/funcoes';
import Button from '../../components/diversos/button';
import { useNavigate } from 'react-router';
import { usePostLoginCpfMutation } from '../../redux/api/authApi';

const logo = require('../../assets/logo_santarita.png');

const LoginSenha = () => {
    const [postData, { data: paciente, error }] = usePostLoginCpfMutation();
    let navigate = useNavigate();
    const [senha, setSenha] = useState('');
    const [loading, setLoading] = useState(false);
    const iSenha = useRef<HTMLInputElement>(null);
    const onChangeSenha = (e: any) => {
        setSenha(e.target.value);
    };

    useEffect(() => {
        if (error === undefined) return;
        tratarErro(error);
    }, [error]);

    useEffect(() => {
        if (paciente === undefined) return;
        if (paciente.token && paciente.token !== '') {
            window.location.replace('/');
        }
    }, [paciente]);

    const handleLogin = async (e: any) => {
        e.preventDefault();
        const cpf = getCpf();

        if (senha.trim() === '') {
            msgAtencao('Favor informar a senha.');
            if (iSenha.current) iSenha.current.focus();
            return;
        }
        setLoading(true);
        postData({ cpf, item: { senha } })
            .unwrap()
            .then(() => setLoading(false))
            .catch(() => setLoading(false));
    };

    return (
        <Flex minHeight="100vh" width="full" align="center" justifyContent="center">
            <Box
                px={4}
                width="full"
                maxWidth={{ lg: '500px', xl: '500px', base: '90%' }}
                borderRadius={4}
                textAlign="center"
                boxShadow="dark-lg"
            >
                <Box p={4}>
                    <Box display="flex" justifyContent="center" alignItems="center">
                        <Image src={logo} />
                    </Box>
                    <Box justifyContent="center" alignItems="center">
                        <Text fontSize="2xl" fontWeight="500">
                            LOGIN
                        </Text>
                    </Box>
                    <Box my={4} textAlign="left">
                        <form
                            onSubmit={(e) => {
                                e.preventDefault();
                                handleLogin(e);
                            }}
                        >
                            <FormControl>
                                <FormLabel>Senha</FormLabel>
                                <Input
                                    placeholder="Informe a Senha"
                                    maxLength={20}
                                    value={senha}
                                    onChange={onChangeSenha}
                                    autoComplete="off"
                                    type="password"
                                    ref={iSenha}
                                    autoFocus
                                />
                            </FormControl>
                            <Button mt={4} mr={4} disabled={loading} onClick={handleLogin} w="150px">
                                {loading && <Loading />}
                                <span>Entrar</span>
                            </Button>
                            <a href="/alterar">
                                <Button mt={4} mr={4} w="150px">
                                    <span>Esqueci a Senha</span>
                                </Button>
                            </a>
                        </form>
                    </Box>
                </Box>
            </Box>
        </Flex>
    );
};

export default LoginSenha;
