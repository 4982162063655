import { baseApi } from './baseApi';

const apiWithTag = baseApi.enhanceEndpoints({
    addTagTypes: ['Especialidades', 'Conveniados', 'HorarioMes', 'Horario'],
});

export const clinicaApi = apiWithTag.injectEndpoints({
    endpoints: (builder) => ({
        getEspecialidades: builder.query<any, { tipo: string; cidade: string }>({
            query({ tipo, cidade }) {
                let url = `auth/clinica/especialidades/${tipo}/${cidade}`;
                return {
                    url,
                };
            },
            providesTags: (result) => ['Especialidades'],
        }),
        getConveniados: builder.query<any, { especialidade: string; cidade: string }>({
            query({ especialidade, cidade }) {
                let url = `auth/clinica/conveniados/${especialidade}/${cidade}`;
                return {
                    url,
                };
            },
            providesTags: (result) => ['Conveniados'],
        }),
        getHorarioMes: builder.query<any, { conveniado: string; data: string; cidade: string }>({
            query({ conveniado, data, cidade }) {
                let url = `auth/clinica/conveniados/horariosmes/${conveniado}/${data}/${cidade}`;
                return {
                    url,
                };
            },
            providesTags: (result) => ['HorarioMes'],
        }),
        getHorario: builder.query<any, { conveniado: string; data: string; cidade: string }>({
            query({ conveniado, data, cidade }) {
                let url = `auth/clinica/conveniados/horarios/${conveniado}/${data}/${cidade}`;
                return {
                    url,
                };
            },
        }),
        postAgendar: builder.mutation<any, { item: {} }>({
            query({ item }) {
                return {
                    url: 'auth/clinica/agendar',
                    method: 'POST',
                    body: item,
                };
            },
            invalidatesTags: (result) => ['HorarioMes', 'Horario'],
        }),
        getConsultas: builder.query<any, { id: string }>({
            query({ id }) {
                let url = `auth/clinica/horarios/${id}`;
                return {
                    url,
                };
            },
        }),
        putConsultas: builder.mutation<any, { id: string }>({
            query({ id }) {
                let url = `auth/clinica/horarios/${id}`;
                return {
                    url: url,
                    method: 'PUT',
                };
            },
        }),
    }),
    overrideExisting: true,
});

export const {
    useLazyGetEspecialidadesQuery,
    useLazyGetConveniadosQuery,
    useLazyGetHorarioMesQuery,
    useLazyGetHorarioQuery,
    usePostAgendarMutation,
    useLazyGetConsultasQuery,
    usePutConsultasMutation,
} = clinicaApi;
