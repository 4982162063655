import { Link, useColorModeValue, Box, Tooltip } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
// @ts-ignore
const NavLink = ({ children, ...props }) => {
    const location = useLocation();
    const verificaAtivo = (caminho: string) => {
        if (caminho === '/') {
            return location.pathname === caminho ? 'thick solid #F4D728;' : undefined;
        } else {
            return location.pathname.startsWith(caminho) ? 'thick solid #F4D728;' : undefined;
        }
    };

    return (
        <Tooltip hasArrow label={props.tooltip}>
            <Link
                as={RouterLink}
                {...props}
                background={'#7dbe91'}
                borderBottom={verificaAtivo(props.to)}
                color="white"
                w="100%"
                maxW={{ lg: '250px', xl: '250px', base: undefined }}
                height="50px"
                float="left"
                fontWeight="bold"
                fontSize="md"
                display="flex"
                alignItems="center"
                _hover={{
                    textDecoration: 'none',
                    fontSize: 'lg',
                }}
                justifyContent="center"
                to={props.to}
            >
                <Box textAlign="center">{children}</Box>
            </Link>
        </Tooltip>
    );
};
export default NavLink;
