import { useEffect, useState, useRef } from 'react';
import {
    ChakraProvider,
    Box,
    Text,
    VStack,
    Grid,
    theme,
    Image,
    Flex,
    FormLabel,
    GridItem,
    FormControl,
    Input,
    HStack,
} from '@chakra-ui/react';
import axios from '../../helpers/axios';
import { formatarCartao, msgAtencao, msgErro, adicionaZero, abrirPDF, formatarValor } from '../../helpers/funcoes';
import Button from '../../components/diversos/button';
import Loading from '../../components/Loading';
import { ToastContainer } from 'react-toastify';
import { isMobile } from 'react-device-detect';

const logo = require('../../assets/logo_santarita.png');

const Pagamento = () => {
    let chave = new URLSearchParams(window.location.search).get('chave');

    const [dados, setDados] = useState({
        conveniado: '',
        cliente: '',
        datahora: '',
        valor: 0.0,
        pago: false,
    });

    const [item, setItem] = useState({
        cartaoNumero: '',
        cartaoNome: '',
        cartaoMes: '',
        cartaoAno: '',
        cartaoCodigo: '',
        email: '',
        cartaoValidade: '',
    });
    const [erro, setErro] = useState('');
    const [loading, setLoading] = useState(false);
    const cartaoNumero = useRef<HTMLInputElement>(null);
    const cartaoNome = useRef<HTMLInputElement>(null);
    const cartaoMes = useRef<HTMLInputElement>(null);
    const cartaoAno = useRef<HTMLInputElement>(null);
    const cartaoCodigo = useRef<HTMLInputElement>(null);

    function buscarDados() {
        if (chave && chave !== 'PDF') {
            axios
                .get(`/clinica/${chave}`)
                .then((response) => {
                    setDados({
                        conveniado: response.data.conveniado,
                        cliente: response.data.cliente,
                        datahora: response.data.datahora,
                        valor: response.data.valor,
                        pago: response.data.pago,
                    });
                })
                .catch((error) => {
                    if (error.response) setErro(error.response.data.error);
                    else {
                        setErro('Não foi possível carregar as informações da consulta.');
                        setDados({
                            conveniado: '',
                            cliente: '',
                            datahora: '',
                            valor: 0,
                            pago: false,
                        });
                    }
                });
        }
        return;
    }

    useEffect(() => {
        buscarDados();
    }, []);

    function handleChange(evt: any) {
        let itemAux = item;
        if (evt.target.name === 'cartaoNumero') {
            let valor = formatarCartao(evt.target.value);
            itemAux = { ...itemAux, [evt.target.name]: valor };
        } else itemAux = { ...itemAux, [evt.target.name]: evt.target.value };
        setItem(itemAux);
    }

    function handleConfirmar() {
        if (loading) return;
        setErro('');

        if (item.cartaoNumero.trim() === '') {
            msgAtencao('Número do cartão deve ser informado.');
            if (cartaoNumero.current) cartaoNumero.current.focus();
            return;
        }
        if (item.cartaoNome.trim() === '') {
            msgAtencao('Nome impresso no cartão deve ser informado.');
            if (cartaoNome.current) cartaoNome.current.focus();
            return;
        }
        if (item.cartaoMes.trim() === '') {
            msgAtencao('Validade do cartão deve ser informada.');
            if (cartaoMes.current) cartaoMes.current.focus();
            return;
        }
        if (item.cartaoAno.trim() === '') {
            msgAtencao('Validade do cartão deve ser informada.');
            if (cartaoAno.current) cartaoAno.current.focus();
            return;
        }
        if (item.cartaoCodigo.trim() === '') {
            msgAtencao('Código de segurança deve ser informado.');
            if (cartaoCodigo.current) cartaoCodigo.current.focus();
            return;
        }
        setLoading(true);
        let itemEnviar = { ...item };
        let cartaoValidade;
        if (item.cartaoAno.length === 2)
            cartaoValidade = adicionaZero(item.cartaoMes) + '/20' + adicionaZero(item.cartaoAno);
        else if (item.cartaoAno.length === 4)
            cartaoValidade = adicionaZero(item.cartaoMes) + '/' + adicionaZero(item.cartaoAno);
        else {
            msgAtencao('Ano deve ser informado corretamente.');
            if (cartaoAno.current) cartaoAno.current.focus();
            return;
        }
        itemEnviar.cartaoValidade = cartaoValidade;
        let janela: Window | null = null;
        if (!isMobile) janela = window.open('?chave=PDF', '_blank');
        axios
            .post(`/clinica/${chave}`, {
                ...itemEnviar,
            })
            .then((retorno) => {
                axios(`/recibo/${retorno.data.id}`, {
                    method: 'GET',
                    responseType: 'blob',
                })
                    .then((resposta) => {
                        abrirPDF(resposta, janela);
                        setLoading(false);
                        resetar();
                        buscarDados();
                    })
                    .catch(() => {
                        if (!isMobile && janela) janela.close();
                        setLoading(false);
                        setErro('Não foi possível realizar o download do PDF do recibo.');
                        msgErro('Não foi possível realizar o download do PDF do recibo.');
                        setTimeout(() => {
                            document.location.reload();
                        }, 4000);
                    });
            })
            .catch((error) => {
                if (!isMobile && janela) janela.close();
                setLoading(false);
                setErro('');
                let mensagem = '';
                if (error.response.data) mensagem = error.response.data.error;
                else mensagem = 'Um erro inesperado ocorreu na baixa.';
                setErro(mensagem);
                msgErro(mensagem);
            });
    }

    function resetar() {
        setItem({
            cartaoNumero: '',
            cartaoNome: '',
            cartaoMes: '',
            cartaoAno: '',
            cartaoCodigo: '',
            email: '',
            cartaoValidade: '',
        });
    }

    return (
        <ChakraProvider theme={theme}>
            <ToastContainer />
            <Flex width="full" align="center" justifyContent="center">
                <Box fontSize="xl" boxShadow="dark-lg" maxW="900px" width="92%" textAlign="left">
                    <Grid minH="100vh" p={3} margin="auto">
                        <VStack>
                            <Image src={logo} />
                            {!chave ? (
                                <Text>Chave da consulta não localizada.</Text>
                            ) : (
                                <>
                                    {chave === 'PDF' ? (
                                        <Text>Aguarde. Seu recibo está sendo gerado.</Text>
                                    ) : (
                                        <>
                                            <Box w="100%" pb={2}>
                                                <FormLabel w="100%" m={0}>
                                                    Conveniado
                                                </FormLabel>
                                                <Text w="100%">{dados.conveniado}</Text>
                                            </Box>
                                            <Box w="100%" pb={2}>
                                                <FormLabel w="100%" m={0}>
                                                    Cliente
                                                </FormLabel>
                                                <Text w="100%">{dados.cliente}</Text>
                                            </Box>
                                            <Box w="100%" pb={2}>
                                                <FormLabel w="100%" m={0}>
                                                    Data/Hora
                                                </FormLabel>
                                                <Text w="100%">{dados.datahora}</Text>
                                            </Box>
                                            <Box w="100%" pb={2}>
                                                <FormLabel w="100%" m={0}>
                                                    Valor
                                                </FormLabel>
                                                <Text w="100%">R$ {formatarValor(dados.valor)}</Text>
                                            </Box>
                                            {dados.pago ? (
                                                <Text fontWeight="bold">Consulta já está paga.</Text>
                                            ) : (
                                                <>
                                                    <Box as="form" id="frmDados" w="100%" mt={5}>
                                                        <Grid
                                                            templateColumns="repeat(2, 1fr)"
                                                            w="100%"
                                                            gap="10px"
                                                            maxW="500px"
                                                        >
                                                            <GridItem colSpan={2}>
                                                                <FormControl id="cartaoNumero">
                                                                    <FormLabel>Número do Cartão</FormLabel>
                                                                    <Input
                                                                        placeholder="XXXX-XXXX-XXXX-XXXX"
                                                                        name="cartaoNumero"
                                                                        value={item.cartaoNumero}
                                                                        onChange={(e) => handleChange(e)}
                                                                        ref={cartaoNumero}
                                                                    />
                                                                </FormControl>
                                                            </GridItem>
                                                            <GridItem colSpan={2}>
                                                                <FormControl id="cartaoNome">
                                                                    <FormLabel>Nome Impresso no Cartão</FormLabel>
                                                                    <Input
                                                                        placeholder="Nome"
                                                                        name="cartaoNome"
                                                                        value={item.cartaoNome}
                                                                        onChange={(e) => handleChange(e)}
                                                                        ref={cartaoNome}
                                                                    />
                                                                </FormControl>
                                                            </GridItem>
                                                            <GridItem
                                                                colSpan={{
                                                                    lg: 1,
                                                                    xl: 1,
                                                                    base: 2,
                                                                }}
                                                            >
                                                                <FormControl id="validadeCartao">
                                                                    <FormLabel>Validade do Cartão</FormLabel>
                                                                    <HStack>
                                                                        <Input
                                                                            placeholder="MM"
                                                                            name="cartaoMes"
                                                                            id="cartaoMes"
                                                                            width="100px"
                                                                            value={item.cartaoMes}
                                                                            onChange={(e) => handleChange(e)}
                                                                            ref={cartaoMes}
                                                                            maxLength={2}
                                                                        />
                                                                        <Input
                                                                            placeholder="AA"
                                                                            name="cartaoAno"
                                                                            id="cartaoAno"
                                                                            width="100px"
                                                                            value={item.cartaoAno}
                                                                            onChange={(e) => handleChange(e)}
                                                                            ref={cartaoAno}
                                                                            maxLength={2}
                                                                        />
                                                                    </HStack>
                                                                </FormControl>
                                                            </GridItem>
                                                            <GridItem colSpan={1}>
                                                                <FormControl id="cartaoCodigo">
                                                                    <FormLabel>Cód. Segurança</FormLabel>
                                                                    <Input
                                                                        placeholder="123"
                                                                        name="cartaoCodigo"
                                                                        value={item.cartaoCodigo}
                                                                        onChange={(e) => handleChange(e)}
                                                                        ref={cartaoCodigo}
                                                                        maxLength={3}
                                                                    />
                                                                </FormControl>
                                                            </GridItem>
                                                            <GridItem colSpan={2}>
                                                                <FormControl id="email">
                                                                    <FormLabel>E-mail para envio do recibo</FormLabel>
                                                                    <Input
                                                                        placeholder="teste@teste.com.br"
                                                                        name="email"
                                                                        value={item.email}
                                                                        onChange={(e) => handleChange(e)}
                                                                    />
                                                                </FormControl>
                                                            </GridItem>
                                                        </Grid>
                                                        <Box w="50%" mt={5}>
                                                            {erro !== '' && (
                                                                <Box mb={1}>
                                                                    <Text color="red" fontWeight="bold">
                                                                        {erro}
                                                                    </Text>
                                                                </Box>
                                                            )}

                                                            <Button
                                                                width="full"
                                                                onClick={handleConfirmar}
                                                                isDisabled={loading || dados.conveniado === ''}
                                                                maxW="150px"
                                                            >
                                                                {loading && <Loading />}
                                                                <Text>Pagar</Text>
                                                            </Button>
                                                        </Box>
                                                    </Box>
                                                </>
                                            )}
                                        </>
                                    )}
                                </>
                            )}
                        </VStack>
                    </Grid>
                </Box>
            </Flex>
        </ChakraProvider>
    );
};

export default Pagamento;
