import { Button as Botao, ButtonProps, forwardRef } from '@chakra-ui/react';

const Button = forwardRef<ButtonProps, 'button'>((props, ref) => {
    return (
        <Botao bg={props.colorScheme === undefined ? '#7dbe91' : undefined} color="white" {...props} ref={ref}>
            {props.children}
        </Botao>
    );
});
export default Button;
