import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay,
    useColorModeValue,
} from '@chakra-ui/react';
import { useRef } from 'react';
import Button from './button';

interface Props {
    titulo: string;
    texto: string;
    isOpen: boolean;
    toggleOpen: Function;
    apagar: Function;
}

const ConfirmarExclusao = ({ titulo, texto, isOpen, toggleOpen, apagar }: Props) => {
    const onClose = () => toggleOpen(false);
    const cancelRef = useRef(null);
    const onDelete = () => {
        apagar();
        toggleOpen(false);
    };
    const bgBox = useColorModeValue('white', 'gray.800');

    return (
        <AlertDialog
            isOpen={isOpen}
            leastDestructiveRef={cancelRef}
            onClose={onClose}
            closeOnOverlayClick={false}
            onEsc={onClose}
            isCentered
        >
            <AlertDialogOverlay>
                <AlertDialogContent bg={bgBox}>
                    <AlertDialogHeader fontSize="lg" fontWeight="bold">
                        {titulo}
                    </AlertDialogHeader>

                    <AlertDialogBody>{texto}</AlertDialogBody>

                    <AlertDialogFooter>
                        <Button colorScheme="red" onClick={onDelete}>
                            Confirmar
                        </Button>
                        <Button ref={cancelRef} onClick={onClose} ml={3}>
                            Voltar
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
    );
};

export default ConfirmarExclusao;
